import { on } from './utils/dom';
import Routing from './routes';

function submitSearchInput(searchPhraseInput: HTMLInputElement) {
    const { value } = searchPhraseInput;
    const searchPhrase = value.trim();
    const route = value ? Routing?.generate('search', { searchPhrase }) : Routing?.generate('search_view');

    if (!route) {
        throw new Error('No search route');
    }

    window.location.href = route;
}

on('submit', '[data-js-search-mobile]', (event, el) => {
    const input = el.querySelector<HTMLInputElement>('[data-js-search-input-mobile]');

    if (input) {
        event.preventDefault();
        submitSearchInput(input);
    }
});

on(
    'keypress',
    '[data-js-search-input]',
    (event:KeyboardEvent) => {
        if (event.key === 'Enter') {
            event.preventDefault();

            if (event.target instanceof HTMLInputElement) {
                event.target.parentElement?.querySelector<HTMLButtonElement>('[data-js-search-button]')?.click();
            }
        }
    },
);
